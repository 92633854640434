<template>
  <div>
    <slot name="action">
      <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Ajouter</a>
    </slot>
    <b-modal
      id="modal-footer-sm"
      ref="modal"
      size="lg"
      hide-footer
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <template #modal-header>
        <h5 class="modal-title" id="staticBackdropLabel">AGENT</h5>
        <button
          type="button"
          class="style_btn btn-close"
          data-bs-dismiss="modal"
          @click.prevent="cancel"
          aria-label="Close"
        ></button>
      </template>
      <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <PTabView :activeIndex.sync="activeTab">
            <PTabPanel header="Informations Générales">
              <h5 class="mt-2">Etat civil</h5>
              <div class="row">
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.nom"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="nom"
                        name="nom"
                        placeholder="Nom"
                      />
                      <label for="nom">Nom</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.prenom"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="prenom"
                        name="prenom"
                        placeholder="Nom"
                      />
                      <label for="prenom">Prénom</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <select
                        :value="editableItem.sexe"
                        @input="handleInput"
                        class="form-select form-select mb-4"
                        id="sexe"
                        name="sexe"
                        aria-label="Votre sexe"
                      >
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      <label for="sexe">Sexe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.matricule"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="matricule"
                        name="matricule"
                        placeholder="Matricule"
                      />
                      <label for="matricule">Matricule</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <h5>Adresse et contacts</h5>
              <div class="row">
                <div class="col-sm-6">
                  <ValidationProvider
                    :rules="`required|${
                      editableItem.id == null ? 'available:EMAIL' : ''
                    }`"
                    v-slot="{ errors }"
                  >
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.email"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                      />
                      <label for="email">Email</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider
                    :rules="`required|${
                      editableItem.id == null ? 'available:PHONE|' : ''
                    }exact:8`"
                    v-slot="{ errors }"
                  >
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.phone"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Téléphone"
                      />
                      <label for="phone">Téléphone</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <!-- <div class="col-sm-6">
                <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                    <input
                        :value="editableItem.adresse"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="adresse"
                        name="adresse"
                        placeholder="Adresse"
                    />
                    <label for="adresse">Adresse</label>
                    <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                        err
                    }}</span>
                    </div>
                </ValidationProvider>
                </div>
                <div class="col-sm-6">
                <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                    <input
                        :value="editableItem.codePostal"
                        @input="handleInput"
                        type="text"
                        class="form-control"
                        id="codePostal"
                        name="codePostal"
                        placeholder="Code postal"
                    />
                    <label for="codePostal">Code Postal</label>
                    <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                        err
                    }}</span>
                    </div>
                </ValidationProvider>
                </div> -->
              </div>

              <template v-if="editableItem">
                <h5>Fonction et habilitations</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select
                          id="typeOrganisme"
                          name="typeOrganisme"
                          placeholder="Type d'organisme"
                          option-label="libelle"
                          option-value="id"
                          v-model="editableItem.typeOrganisme"
                          :options="typeOrganismes"
                          class="form-select"
                        >
                        </c-select>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <label for="typeOrganisme">Type d'organisme</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-6">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select
                          id="organisme"
                          name="organisme"
                          option-label="intitule"
                          option-value="id"
                          v-model="editableItem.organisme"
                          :options="organismes"
                          class="form-select"
                        >
                        </c-select>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <label for="organisme">Organisme</label>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div class="col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="form-floating mb-3">
                        <c-select
                          id="fonction"
                          name="fonction"
                          option-label="nom"
                          option-value="id"
                          v-model="editableItem.fonction"
                          :options="organismeFonctions"
                          class="form-select"
                        >
                        </c-select>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <label for="fonction">Fonction</label>
                      </div>
                    </ValidationProvider>
                  </div>
                </div>
              </template>

              <h5>Identifiant</h5>
              <ValidationProvider
                :rules="`required${editableItem.id == null ? '|available:EMAIL' : ''}`"
                v-slot="{ errors }"
              >
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.email"
                    @input="handleInput"
                    type="email"
                    class="form-control"
                    id="email"
                    name="email"
                    placeholder="Votre email"
                  />
                  <label for="email">Adresse Email</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
              <div class="row">
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.password"
                        @input="handleInput"
                        type="password"
                        class="form-control"
                        :class="{
                          'is-valid':
                            editableItem.password != undefined &&
                            editableItem.password == editableItem.password1,
                        }"
                        id="password"
                        name="password"
                        placeholder="Password"
                      />
                      <label for="password">Mot de passe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        :value="editableItem.password1"
                        :class="{
                          'is-valid':
                            editableItem.password != undefined &&
                            editableItem.password == editableItem.password1,
                        }"
                        @input="handleInput"
                        type="password"
                        class="form-control"
                        id="password1"
                        name="password1"
                        placeholder="Password"
                      />
                      <label for="password1">Confirmer le mot de passe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>

              <div class="d-grid gap-2">
                <button
                  @click.prevent="save"
                  type="submit"
                  class="style_btn btn btn-success btn-lg"
                >
                  Enrégistrer
                </button>
              </div>
            </PTabPanel>

            <PTabPanel header="Attributions">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form mb-3">
                  <d-pick-list v-model="items">
                    <template #item="{ item }">
                      {{ item.name }}
                    </template>
                  </d-pick-list>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </PTabPanel>
          </PTabView>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DPickList from "@/components/common/DPickList.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    Multiselect,
    DPickList,
  },
  data() {
    return {
      loading: false,
      editableItem: {},
      items: [],
      activeTab: 0,
    };
  },
  watch: {
    privileges() {
      this.items = [this.privileges, []];
    },
    item: {
      handler(val, oldVal) {
        const oldId = oldVal != null && oldVal.id != null ? oldVal.id : null;
        this.editableItem = { ...this.item };

        if (val != null && val.id != null && val.id != oldId) {
          this.loadAgentData();
        }
      },
      immediate: true,
      deep: true,
    },
    "editableItem.typeOrganisme": {
      handler() {
        if (this.editableItem.typeOrganisme != null) {
          this.fetchOrganismesByTypeOrganimeId({
            typeOrganismeId: this.editableItem.typeOrganisme.id,
          });
        }
      },
      immedite: true,
    },
    "editableItem.organisme": {
      handler() {
        if (this.editableItem.organisme != null) {
          console.log(this.editableItem.organisme);
          this.fetchOrganismeFonctionByOrganimeId({
            organismeId: this.editableItem.organisme.id,
          });
        }
      },
      immedite: true,
    },
  },
  created() {
    this.fetchTypeOrganismes();
    this.fetchPrivileges({
      pageNumber: 0,
      pageSize: 2000,
    });
  },
  computed: {
    ...mapGetters({
      organismes: "organisme/organismes",
      typeOrganismes: "organisme/typeOrganismes",
      organismeFonctions: "organisme/organismeFonctions",
      privileges: "privilege/privileges",
    }),
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    ...mapActions({
      fetchAgent: "agent/fetchAgent",
      fetchOrganismeFonctionByOrganimeId: "organisme/fetchOrganismeFonctionByOrganimeId",
      fetchOrganismesByTypeOrganimeId: "organisme/fetchOrganismesByTypeOrganimeId",
      fetchPrivileges: "privilege/fetchPrivileges",
      fetchTypeOrganismes: "organisme/fetchTypeOrganismes",
    }),
    loadAgentData() {
      if (this.editableItem != null && this.editableItem.id != null) {
        this.loading = true;
        this.fetchAgent({
          agentId: this.editableItem.id,
        })
          .then((data) => {
            this.loading = false;

            this.editableItem = {
              ...this.editableItem,
              ...data,
            };

            const itemIds = this.editableItem.attributions.map((el) => el.id);
            this.items = [
              this.privileges.filter((it) => !itemIds.includes(it.id)),
              this.editableItem.attributions,
            ];
          })
          .catch((err) => {
            this.loading = false;
            this.$toast.error("Erreur de chargement de donnée", {
              position: "top-right",
              duration: 8000,
            });
          });
      }
    },
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.editableItem.attributions = this.items[1];
      console.log(this.editableItem);

      this.$emit("saved", this.editableItem);
      this.hide();
    },
  },
};
</script>
