<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des agents</h4>
          </div>
          <form class="d-flex my-1 me-2">
            <div class="input-group me-4">
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button
                @click.prevent="submitQuery"
                type="submit"
                class="style_btn btn btn-primary"
                id="button-query"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
          <div class="dropdown me-3">
            <button
              :disabled="selected.length == 0"
              class="btn style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action des comptes
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li class="border">
                <a class="dropdown-item" @click="activerCompte" href="#">Activer</a>
                <a class="dropdown-item" @click="desactiverCompte(false)" href="#"
                  >Désactiver</a
                >
                <a class="dropdown-item" @click="reinitializerCompte" href="#"
                  >Réinitialiser</a
                >
              </li>
              <li class="border">
                <a class="dropdown-item" @click="reinitializerCompte" href="#"
                  >Envoyer mail</a
                >
              </li>
            </ul>
          </div>
          <agent-edit
            v-if="$can('CREATE', 'AGENT')"
            ref="agentEditor"
            @saved="updateOrCreateAgent"
            btnText="Créer un agent"
            :item="activeAgent"
            title="Ajouter un agent"
          />
        </div>
        <div class="bg-white p-3 rounded">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              :sort-by.sync="mxPagination.sortBy"
              :sort-desc.sync="mxPagination.sortDesc"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>

              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(enabled)="data">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    v-model="data.item.enabled"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="data.item.enabled"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{{
                    data.item.enabled ? "Actif" : "Inactif"
                  }}</label>
                </div>
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editAgent(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a class="btn btn-info rounded-0 btn-sm me-1"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    @click.prevent="destroyAgent(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    ><i class="bi bi-trash"></i
                  ></a>
                  <!-- <button type="button" class="btn btn-outline-secondary">Réinitialiser</button> -->
                  <button
                    type="button"
                    class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span class="visually">Compte</span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                      <a @click="activerCompte" class="dropdown-item" href="#">Activer</a>
                    </li>
                    <li>
                      <a @click="desactiverCompte" class="dropdown-item" href="#"
                        >Désactiver</a
                      >
                    </li>
                    <li>
                      <a @click="reinitializerCompte" class="dropdown-item" href="#"
                        >Réintialiser</a
                      >
                    </li>
                    <li><hr class="dropdown-divider" /></li>
                    <li><a class="dropdown-item" href="#">Envoyer mail</a></li>
                  </ul>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ (mxPagination.page - 1) * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import AgentEdit from '@/components/espace/admin/agent/AgentEdit'
import { paginatorMixin } from "@/mixins/paginator-mixin";
import AgentEdit from "../../../components/espace/admin/agent/AgentEdit.vue";

export default {
  components: {
    AgentEdit,
    // AgentEdit
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      filterOptions: [
        // {
        //   column: 'departement',
        //   value: '',
        //   label: 'Département',
        //   items: []
        // },
        // {
        //   column: 'commune',
        //   value: '',
        //   label: 'Commune',
        //   items: []
        // },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        // _queryOptions: {
        //   departement: 'departement',
        //   skills: 'skills__in',
        //   languages: 'languages__in',
        //   availabilities: 'availability__in',
        //   level: 'level',
        //   price_min: 'budget__gte',
        //   price_max: 'budget__lte'
        // },
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeAgent: {
        nom: null,
        prenom: null,
        age: null,
        matricule: null,
        phone: null,
        phoneAlternatif: null,
        organisme: null,
        typeOrganisme: null,
        fonction: null,
      },
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Agents",
          active: true,
        },
      ],
    };
  },
  created() {
    this.submitQuery();
  },

  computed: {
    ...mapGetters({
      // agents: 'agent/agents',
      pagination: "agent/pagination",
    }),
    tableFields() {
      return [
        "#",
        "index",
        // { key: 'matricule', label: 'Matricule' },
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "email", label: "Email" },
        { key: "enabled", label: "Statut" },
        { key: "phone", label: "Téléphone" },
        { key: "organismeFonctionNom", label: "Fonction" },
        // { key: 'commune.nom', label: 'Commune' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    ...mapActions({
      fetchAgents: "agent/fetchAgents",
      createOrUpdateAgent: "agent/createOrUpdateAgent",
      resetAgentAccount: "agent/resetAgentAccount",
      deleteAgent: "agent/deleteAgent",
    }),
    submitQuery() {
      this.mxInitializePaginator({
        queryModel: "agent",
        search: this.search,
        fetcherMethod: "agent/fetchAgents",
        dataGetter: "agent/agents",
        paginationGetter: "agent/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "nom",
          sortDesc: false,
          descending: false,
          page: 0,
          rowsPerPage: 20,
        },
        filterOptions: this.filterOptions,
        searchFields: ["search"],
      });
    },
    reinitializerCompte() {
      this.resetAgentAccount(this.selected).then(() => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 5000,
        });
      });
    },
    desactiverCompte() {},
    activerCompte() {},
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    editAgent(agent) {
      this.activeAgent = { ...agent };
      this.$refs["agentEditor"].show();
      // this.$router.push({ name: 'espace.admin.agents.edit' })
    },
    async destroyAgent(agent) {
      await this.$dialog.confirm({
        size: "md",
        centered: true,
        variant: "success",

        text: "Voulez-vous vraiment supprimer cet élément?",
        actions: {
          false: {
            text: "Non",
            variant: "outline-success",
          },
          true: {
            text: "Oui",
            variant: "success",
            handle: async () => {
              this.deleteAgent(agent);
            },
          },
        },
      });
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateAgent(agent) {
      this.createOrUpdateAgent(agent)
        .then((data) => {
          console.log(data);
          this.$toast.success("Opération réussie!", {
            position: "top-right",
            duration: 5000,
          });
        })
        .catch((err) => {
          // console.log(data)
          this.$toast.error(err.message, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
